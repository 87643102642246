/* CSS Reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  /* Use Lato font for a professional look */
  font-family: 'Lato', 'Arial', sans-serif;
}

html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; /* This line makes the background fixed even when content is scrolled */
}

.section {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
}

/* iPhone and major other phones */
@media only screen and (max-width: 769px) {
  .App {
    /* Adjust background size as requested */
    background-size: fixed;
    background-attachment: fixed; /* Changes the background to scroll with the page on smaller screens */
  }

  .section {
    /* Apply padding and potentially other styles for smaller screens */
    padding: 20px;
  }
}

/* All the iPads and Computers */
@media only screen and (min-width: 769px) {
  .App {
    /* Fixed background for larger screens */
    background-attachment: fixed;
  }
}
