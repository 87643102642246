.education-skills-container {
    padding: 4rem;
    background-color: #fff;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
    max-width: 1200px;
    margin: auto;
}

.section-title {
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 1.5rem;
}

.education-section, .skills-section, .volunteering-section {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 2rem;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.education-section:hover {
  transform: rotate(2deg);
}

.skills-section:hover {
  transform: scale(1.05);
}

.volunteering-section:hover {
  transform: translateX(10px);
}

.uni-sub {
  margin-bottom: 2rem;
}

.uni-name, .degree-name {
  margin-bottom: 2px;
}

.degree-name {
  font-style: italic;
}

.paragraph {
  padding: 5px;
}

.bolded-title {
  font-weight: bold;
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
}

.skill-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.skill-category-title{
  font-size: 1.6rem;
  margin-top: 5px;
}

.skill-name-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Adjust this value as needed */
}

.skill-items-list {
  /* Styles for your list */
  padding-left: 0; /* Removes default padding if needed */
  list-style-type: none; /* Removes default list bullet points if desired */
}

.skill-items-list li {
  /* Styles for your list items */
  margin-top: 0.25rem; /* Adds space between list items if desired */
}

.skill-heading {
  display: flex;
  margin-bottom: 0.5rem; /* Space below the skill name and proficiency label */
}

.skill-name {
  font-weight: bold;
  margin-bottom: 0.5rem; /* Space below the skill name */
}

.skill-proficiency-beginner {
  align-self: start;
  background-color: #69b3df; 
  color: white;
  border-radius: 12px; /* Rounded corners for the label */
  padding: 0.25rem 0.75rem; /* Padding inside the label */
  font-size: 0.875rem; /* Smaller font size for the label */
  font-weight: bold; /* Bold font for better readability */
  text-transform: uppercase; /* Uppercase text for stylistic choice */
  margin-bottom: 0.5rem; /* Space below the proficiency label */
  margin-left: auto;
}

.skill-proficiency-intermediate {
  align-self: start;
  background-color: #3cb5a6; 
  color: white;
  border-radius: 12px; /* Rounded corners for the label */
  padding: 0.25rem 0.75rem; /* Padding inside the label */
  font-size: 0.875rem; /* Smaller font size for the label */
  font-weight: bold; /* Bold font for better readability */
  text-transform: uppercase; /* Uppercase text for stylistic choice */
  margin-bottom: 0.5rem; /* Space below the proficiency label */
  margin-left: auto;
}

.skill-proficiency-advanced {
  align-self: start;
  background-color: #4caf50; /* Green color for advanced proficiency */
  color: white;
  border-radius: 12px; /* Rounded corners for the label */
  padding: 0.25rem 0.75rem; /* Padding inside the label */
  font-size: 0.875rem; /* Smaller font size for the label */
  font-weight: bold; /* Bold font for better readability */
  text-transform: uppercase; /* Uppercase text for stylistic choice */
  margin-bottom: 0.5rem; /* Space below the proficiency label */
  margin-left: auto;
}

.skill-items-list {
  padding-left: 20px; /* Creates indentation for the list */
  list-style: inside; /* Positions the bullet points inside the content flow */
  margin: 5px 0; /* Adjusts spacing around the list */
}


.event-name {
  font-style: italic;
}

.event-location {
  margin-bottom: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .education-skills-container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .section-title {
        text-align: center;
    }

    .education-image, .volunteer-image {
        margin-top: 1rem;
    }
}
