.contact-section {
  padding: 4rem;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.section-title {
  margin-bottom: 1rem;
  color: #333;
  font-size: 2.5rem;
}

.contact-description {
  margin-bottom: 2rem;
  color: #555;
  font-size: 1.1rem;
  font-style: italic;
}

.contact-form {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.contact-form:hover {
  transform: scale(1.05);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.send-button {
  background: #007bff;
  color: #fff;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.send-button:hover {
  background: #0056b3;
}

.confirmation-message {
  margin-top: 1rem;
  color: green;
  text-align: center;
  font-weight: bold;
}

.error-message {
  margin-top: 1rem;
  color: red;
  text-align: center;
  font-weight: bold;
}
