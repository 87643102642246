@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #333;
  opacity: 0; /* Start invisible */
  animation: fadeIn 1.5s ease-in-out forwards; /* Fade-in effect */
}

.content {
  text-align: center;
  padding: 20px;
}

.title {
  font-size: 3rem;
  font-weight: 300;
  margin: 0;
}

.name {
  font-weight: 700;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: #555;
  margin: 10px 0;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  margin: 20px 0;
}

.skills-container {
  margin-top: 20px;
  height: 50px; /* Fixed height to avoid layout shift */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide the non-active skills */
}

.skill {
  font-size: 1.5rem;
  opacity: 0;
  animation: fadeSkill 3s ease-in-out forwards;
}

@keyframes fadeSkill {
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
}

/* Keyframes for the fade-in effect */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .title, .subtitle, .description {
    font-size: calc(1rem + 1.5vw);
  }
}

/* Add additional styling for SkillCarousel component */
