.site-header {
  opacity: 0;
  background-color: transparent;
  color: #fff;
  padding: 1rem 0;
  position: fixed; /* Make the header fixed */
  width: 100%; /* Ensure the header stretches across the full width */
  top: 0; /* Stick to the top */
  left: 0; /* Align to the left */
  z-index: 1000; /* Ensure it's above other content */
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.brand a {
  color: #000000;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;
}

.navigation a {
  color: #000000;
  text-decoration: none;
  padding: 0.5rem;
  position: relative;
  transition: color 0.3s;
}

.navigation a::before,
.navigation a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #575757;
  transition: transform 0.3s;
}

.navigation a::before {
  top: 0;
  left: 0;
  transform-origin: left;
  animation: none;
}

.navigation a::after {
  bottom: 0;
  right: 0;
  transform-origin: right;
  animation: none;
}

.navigation a:hover::before,
.navigation a:hover::after {
  animation: box-animation 2s linear infinite;
}

.navigation a:hover {
  color: #000000;
}

@keyframes box-animation {
  0%, 100% {
    width: 0;
    height: 2px;
  }
  25% {
    width: 100%;
    height: 2px;
  }
  50% {
    width: 100%;
    height: 2px;
    transform: translateY(-100%);
  }
  75% {
    width: 0;
    height: 2px;
    transform: translateY(-100%);
  }
}

@media (max-width: 768px) {
  .navigation ul {
    display: none; /* Hide navigation on smaller screens */
  }
}