.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
}

.project-title {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 20px;
  width: 100%;
  max-width: 1200px;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  align-items: flex-start;
}

.project-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  color: #000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translate3d(10px, 20px, 0px);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 15px;
}

.project-tech-list {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.project-tech-item {
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.project-link {
  text-decoration: none;
  background: #0066ff;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 12px;
  font-weight: bold;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .project-grid {
      grid-template-columns: 1fr;
  }
}