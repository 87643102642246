.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  max-width: 1200px;
  margin: auto;
}

.about-content {
  flex: 5; /* Takes up 5/8 of the container */
  margin-right: 20px;
}

.about-content h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.about-content p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.about-image {
  flex: 3; /* Takes up 3/8 of the container */
  text-align: center;
}

.about-image img {
  width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

/* Responsive design */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column-reverse;
    text-align: center;
  }

  .about-content {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .about-image {
    max-width: 80%;
    margin: auto;
  }
}